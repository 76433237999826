import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;

  constructor(private http: HttpClient) { }

  async loadAppConfig() {
    const data = await this.http.get('./assets/appsettings.json?d='+ Date.now() / 1000).toPromise();
    this.appConfig = data;
  }

  checkAppConfig() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
  }

  get apiManagersBaseUrl() {
    this.checkAppConfig()
    return this.appConfig.apiManagers;
  }

}
