import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CenterService {

  constructor(private http: HttpClient, public datepipe: DatePipe) { }

  refreshDataForLastUpdate(newCenters: any[]) {
    const _this = this;
    const centers = JSON.parse(localStorage.getItem('centers'));
    if (!centers) {
      return newCenters;
    }
    const filterCenters = newCenters.filter(function (nc) {
      const test = centers.find(x => (x.centerName == nc.centerName) && (x.lastUpdate != nc.lastUpdate));
      // console.log(test);
      if (test) {
        return nc;
      }
    })
    return filterCenters;
  }

  refreshDataForTime(): boolean {
    const _this = this;
    const centers = JSON.parse(localStorage.getItem('centers'));
    const requiredUpdate = centers.find(function (x) {
      const difference = _this.difference(new Date(x.lastUpdate), new Date());
      const time_difference = difference / (1000 * 3600 * 24)
      if (time_difference > 0 && x.lastUpdate != null) {
        return x;
      }
    })
    return requiredUpdate;
  }

  saveCenters(centers: any[]) {
    localStorage.setItem('centers', JSON.stringify(centers));
  }

  saveCenter(center, centerId) {
    localStorage.setItem('center-' + centerId, JSON.stringify(center));
  }

  getCentersLocal() {
    const centers = JSON.parse(localStorage.getItem('centers'));
    return centers;
  }

  getCenterLocal(center){
    // console.log("center service: ");
    // console.log(center);
    const menu = JSON.parse(localStorage.getItem('center-' + center.centerId));
    return menu;
  }

  difference(date1, date2) {
    const date1utc = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const date2utc = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    var day = 1000 * 60 * 60 * 24;
    return (date2utc - date1utc) / day
  }

}
