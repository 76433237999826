// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  menuJson: 'assets/data/menu.json',
  barJson: 'assets/data/bar.json',
  bar1Json: 'assets/data/bar1.json',
  pieJson: 'assets/data/pie.json',
  doughnutParoJson: 'assets/data/doughnutParo.json',
  doughnutJson: 'assets/data/doughnut.json',
  doughnut2Json: 'assets/data/doughnut2.json',
  doughnut3Json: 'assets/data/doughnut3.json',
  doughnut4Json: 'assets/data/doughnut4.json',
  doughnut5Json: 'assets/data/doughnut5.json',
  lineJson: 'assets/data/line.json',
  line1Json: 'assets/data/line1.json',
  customersJson: 'assets/data/customers.json',
  udsJson: 'assets/data/table.json',
  tableJson: 'assets/data/table.json'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
