import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient, private menu: MenuController) { }

  getMenuOnline(): Observable<any>{
    const url = environment.menuJson;
    return this.http.get<any>(
      url
    );
  }

  getMenuLocal(center){
    const result = JSON.parse(localStorage.getItem('center-' + center.centerId));
    // console.log(result);
    return result.menu;
  }

  enableMenu(){
    this.menu.enable(true, 'menu-2');
    this.menu.swipeGesture(true, 'menu-2');
    this.menu.enable(true, 'menu-1');
    this.menu.swipeGesture(true, 'menu-1');
  }

  disableMenu(){
    this.menu.enable(false, 'menu-2');
    this.menu.swipeGesture(false, 'menu-2');
    this.menu.enable(false, 'menu-1');
    this.menu.swipeGesture(false, 'menu-1');
  }
}
