import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  constructor(private appConfigService: AppConfigService, private http: HttpClient) { }



  getMenu(): Observable<string>{
    const url = environment.menuJson;
    return this.http.get<any>(url);
  }

  getLogin(obj){
    const url = this.appConfigService.apiManagersBaseUrl + 'ManagersLogin/GetLogin' +
                '?user=' + obj.user + '&pwd=' + obj.pwd;
    return this.http.post<any>( url, null );
  }

  logout(): void{

  }

}
