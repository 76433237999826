import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AppService } from '../services/app.service';
import { AuthenticationService } from '../services/authentication.service';
import { MessageService } from '../services/message.service';

@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptorService implements HttpInterceptor {

  constructor(private _messageService: MessageService, private _authService: AuthenticationService, private _appService: AppService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._appService.getToken();
    request = request.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
    return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // console.log(err);
    if (!navigator.onLine) {
      // this._messageService.presentToast("Sin conexión. Accederá a la información de manera offline." )
    } else{
      this._messageService.presentToast(err.error )
    }
    if (err.status == 0) {
      this._messageService.presentToast("Ocurrió un problema. Intentelo mas tarde." )
      return of(err.message);
    } else if (err.status == 401) {
      this._authService.logout();
      return of(err.message);
    }
    return throwError(err);
  }
}

