import { AfterContentChecked, AfterViewChecked, Component, DoCheck, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, LoadingController, MenuController } from '@ionic/angular';
import { throwIfEmpty } from 'rxjs/operators';
import { OeePage } from './pages/private/productivity/oee/oee.page';
import { AppService } from './services/app.service';
import { CenterService } from './services/center.service';
import { MenuService } from './services/menu.service';
import { MessageService } from './services/message.service';

interface ActionSheetButton<T = any> {
  text?: string;
  role?: 'cancel' | 'destructive' | 'selected' | string;
  icon?: string;
  cssClass?: string | string[];
  handler?: () => boolean | void | Promise<boolean | void>;
  data?: T;
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {

  public appPages = [];

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  menuSelected: any;
  periodSelected: any = "3";

  centers: any[] = [];
  center: any;

  message: string;

  constructor(
    private _messageService: MessageService,
    private menuController: MenuController, private router: Router,
    private _menuService: MenuService, private _centerService: CenterService,
    private _appService: AppService, public actionSheetController: ActionSheetController
  ) {
    const theme = localStorage.getItem('dark-mode-app');
    if (theme == 'dark') {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
    }
    const context = this;
    this._appService.currentHit.subscribe(function (result) {
      if (result) {

        context.loadData();
      }
    })
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {

    setTimeout(() => {
      this.centers = this._centerService.getCentersLocal();
      if (this.centers) {
        this.centers.forEach((element, i) => {
          if (i == 0) {
            const center = this._centerService.getCenterLocal(element);
            this.appPages = center.menu;
            this.menuSelected = element.centerId;
          }
        });
      } else {
        if (window.location.pathname.includes('private')) {
          this._messageService.presentToast("No cuenta con información de Centros. Comuníquese con el soporte. Gracias.")
          this.router.navigate(['public/']);
        }
      }
    }, 2000);

  }

  preChangeMenu(event) {
    this.menuSelected = event.detail.value;
    this.center = this.centers.find(x => x.centerId == this.menuSelected);
    this.changeMenu(this.center);
  }

  changePeriod(event) {
    this.menuController.toggle('menu-2');
    let result = this._appService.getDataLocal();
    // result.period = event.detail.value;
    this.center.period = this.periodSelected;
    this._appService.saveDataLocal(this.center);
    this.newMessage(result);
  }

  goPage(page) {
    this._appService.saveDataLocal(this.center, page);
    let result = this._appService.getDataLocal();
    let navigationExtras: NavigationExtras = {
      state: {
        page: page
      }
    };
    this.router.navigate(['private/blank-page'], navigationExtras);
    this.newMessage(result);
  }

  async changeMenu(center) {
    // this.menuController.toggle('menu-2');
    this.appPages = this._menuService.getMenuLocal(center);
    const centerGetted = this._centerService.getCenterLocal(center);
    this._appService.saveDataLocal(centerGetted);
    const result = this._appService.getDataLocal();

    this.newMessage(result);
  }

  hideMenu() {
    this.menuController.toggle('menu-1');
  }

  newMessage(result) {
    this._appService.changeMessage(result)
  }

  async onOutletLoaded(component: Component) {
    // console.log(component.constructor.name);
    if (component.constructor.name == 'OeePage') {

      setTimeout(() => {
        let result = this._appService.getDataLocal();
        this.newMessage(result);
      }, 2000);
    }
  }


}
