import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class AppService {

  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();

  private hitSource = new BehaviorSubject(null);
  currentHit = this.hitSource.asObservable();


  constructor(private http: HttpClient, public datepipe: DatePipe) { }

  changeMessage(result: any) {
    this.messageSource.next(result)
  }

  changeHit(result: any) {
    this.hitSource.next(result)
  }

  saveToken(token: string): void {
    localStorage.setItem('tokenApp', JSON.stringify(token));
  }

  saveDataLocal(center, valuesPage?) {
    if (center) {
      if (center.plantaId) { localStorage.setItem('plantaId', JSON.stringify(center.plantaId)); }
      if (center.plantaDescripcion) { localStorage.setItem('plantaDescripcion', JSON.stringify(center.plantaDescripcion)); }
      if (center.plantaPais) { localStorage.setItem('plantaPais', JSON.stringify(center.plantaPais)); }
      if (center.plantaCiudad) { localStorage.setItem('plantaCiudad', JSON.stringify(center.plantaCiudad)); }
      if (center.estado) { localStorage.setItem('estado', JSON.stringify(center.estado)); }
      if (center.inteligencia) { localStorage.setItem('inteligencia', JSON.stringify(center.inteligencia)); }
      if (center.cir_grafico) { localStorage.setItem('cir_grafico', JSON.stringify(center.cir_grafico)); }
      if (center.valores) { localStorage.setItem('valores', JSON.stringify(center.valores)); }
      // console.log("center.period: ");
      // console.log(center.period);
      if (center.period) { localStorage.setItem('period', JSON.stringify(center.period)); }
    }
    if (valuesPage) { localStorage.setItem('valores-page', JSON.stringify(valuesPage)); }
  }

  getDataLocal() {
    const obj = {
      plantaId: JSON.parse(localStorage.getItem('plantaId')),
      plantaDescripcion: JSON.parse(localStorage.getItem('plantaDescripcion')),
      plantaPais: JSON.parse(localStorage.getItem('plantaPais')),
      plantaCiudad: JSON.parse(localStorage.getItem('plantaCiudad')),
      estado: JSON.parse(localStorage.getItem('estado')),
      inteligencia: JSON.parse(localStorage.getItem('inteligencia')),
      cir_grafico: JSON.parse(localStorage.getItem('cir_grafico')),
      valores: JSON.parse(localStorage.getItem('valores')),
      valoresPage: JSON.parse(localStorage.getItem('valores-page')),
      period: JSON.parse(localStorage.getItem('period')) || null
    }
    return obj;
  }

  getToken(): string {
    return "";
  }

  validateToken(): boolean {
    return true;
  }

  getData(url: string): Observable<any> {
    return this.http.get<any>(url + "?d=" + Date.now() / 1000);
  }

}
